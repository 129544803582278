import { graphql } from 'gatsby';
import { withLang } from '../../utils/reactTools';
import { Careers } from '../careers';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/pages/careers.md/" }) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
            pageTheme
            ...heroBannerFragmentFR
            ...imageTextFragmentFR
            ...listFragmentFR
            ...jobsFragmentFR
            careersPageCtaUrl
            careersPageCtaLabel
            brandLogos {
              altText
              logo {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            valuesContent {
              title
              values {
                value
              }
            }
            offerings {
              title
              listItems {
                title
                subtitle
                details
              }
            }
          }
        }
      }
    }
  }
`;

export default withLang(Careers, 'fr');
